// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_bIY3df8xf8BHg128xf", // vos
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/7sI14X4Jg8Rwd7afZG", // vos

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Video Object Segmentation AI 📽",
    HOME_PAGE_TITLE: "Home - Video Object Segmentation AI 📽",
    DASHBOARD_PAGE_TITLE: "Home - Video Object Segmentation AI 📽",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Video Object Segmentation AI 📽",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to segment objects in a video, choose video and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "video_object_seg_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/video-object-seg-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;